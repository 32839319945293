import React from 'react';
import Layout from '../../../components/layouts';
import SEO from '../../../components/common/SEO';
import Banner from '../../../components/features/Banner'
import { graphql } from 'gatsby';
import SignUpBtn from '../../../components/features/SignUpBtn'
import Badge from '../../../components/badge/badge';
import ReactWOW from 'react-wow';
import './rewards.scss';

export const query = graphql`
  query rewards {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    features: prismic {
        allFeatures {
            edges{
                node {
                    meta_title
                    meta_description
                    social_title
                    social_description
                    banner_title
                    banner_subtitle
                    banner_feature_name
                    banner_right_button_text
                    banner_right_button_source
                    question_text
                    question_answer
                    question_bullets {
                        bullet_text
                    }
                    question_image
                    video_source {
                        _linkType
                        __typename
                    }
                    video_statement {
                        text
                    }
                    information_list {
                        title
                        subtitle
                    }
                    steps_title
                    steps_subtitle
                    step_list {
                        image_source
                        title
                        subtitle
                    }
                    
                }
            }
        }
    }
  }
`

 export default function Rewards({data}){
    const features = data.features.allFeatures;
    const seo = {
        title: 'Workhub | Rewards',
        description: 'Workers can redeem their hard earned points through the Rewards catalog for a variety of products. From paddle boards to gaming keyboards, our catalog is bound to have something special for everyone in your workforce.',
        image: '/features/rewards/hero.png',
    }

    const bannerProps = {
        title: 'Rewards',
        featureName: 'rewards',
        mockupImg: '/features/rewards/hero.png'
    }

    return (
        <Layout>
            <SEO {...seo} {...data.site}/>
            <Banner {...bannerProps}/>
            <main className="feature-main">
                <section className="feature-section rewards_header">
                    <div className='rewards_feature_header'>
                        <h1>Incentivization Simplified</h1>
                        <subtitle>Motivate your workers by giving points </subtitle>
                    </div>
                    <div className='rewards_feature_info'>
                        <p>Thoroughly onboarding a new worker can be a boring, unglamorous yet crucial part of welcoming a new worker to your company. Let’s admit it, no one loves sitting through a course on the Transportation of Dangerous Goods or WHMIS, having to read and acknowledge the company’s entire policy manual, or tracking down a photo of their First Aid certificate. Rewards points help sweeten the deal and show you appreciate them getting their responsibilities done in a timely matter.</p>
                        <p>Some customers have seen an <strong>increase in their workplace compliance by over 66%</strong> in 3 months by enabling the Workhub Rewards Program.</p>
                    </div>
                    <div className='rewards_feature_points'>
                        <div className='rewards_feature_list'>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Get Working Sooner:</strong> </p>
                            <div>
                                <p>Workhub customers who use rewards points as part of their onboarding see reduced times to completion, allowing your workforce to get to work sooner.</p>
                            </div>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Set and Forget:</strong> </p>
                            <div>
                                <p>Once your rewards catalog is set up, it requires minimal maintenance and upkeep.</p>
                            </div>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Budget Friendly:</strong> </p>
                            <p>Workhub’s Cost Configuration tool can help dial in your settings and stay in your budget, as well as forecast for future growth.</p>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p> <strong>Show You Care:</strong> </p>
                            <p>Including Rewards in your company’s culture and recognition program can help show that your company cares about their workers, which can reduce churn, increase productivity, and avoid burnout.</p>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p> <strong>Stay Tax-Free:</strong> </p>
                            <p>
                                Workhub has many settings and options that can help you reduce or eliminate the amount of tax you have to pay.*
                                <br/>
                                <br/>
                                <p className='hint'>* We encourage you to meet with an accountant to ensure this program works for your organization.</p>
                            </p>
                        </div>
                    </div>
                </section>
                <section className='feature-section'>
                    <SignUpBtn/>
                </section>

                <section className='feature-section'>
                    <div className='rewards_feature_options'>
                        <ReactWOW animation="fadeInLeft" delay="0.3s">
                            <div className='rewards_images_overlap'>  
                                <img src="/features/rewards/points-history-template.png" alt="rewards program page" />
                            </div>
                        </ReactWOW>
                        <div>
                            <h1>A Little Something For Anyone</h1>
                            <p>
                            The reality of the modern workplace is finding skilled people to do the job is getting harder and harder. Keeping your best workers happy is key to retention, productivity and saving money. <strong>Workhub can help by rewarding your workers with points for completing items within the software.</strong> Once an worker has enough points, they can redeem them for a variety of items from top name brands. There’s something in our catalog for everyone and prices are always competitive!
                                <br />
                                <br />
                                Items are shipped directly to your workers. Whether up north at an oil camp or working across the country from home, Workhub has you covered!
                            </p>
                        </div>
                    </div>
                    <div className='rewards_feature_options'>
                        <div>
                            <h1>Uniforms Made Easy</h1>
                            <p>
                            Having a uniform for your workforce is an effective way to increase brand perception, sense of purpose and belonging in your workforce, and increased company morale. Workhub can help! Our one-of-a-kind fulfillment center has the capability of putting your company’s logo on almost anything you can think of. From mugs to hats to shirts to parkas, Workhub has it all. Workhub’s prices are always competitive and best of all, <strong>no minimums</strong>. 
                                <br />
                                <br />
                                Our apparel also comes in a handful of shades that are designed to make your logos pop! We regularly stock sizes small to 2XL and can order additional sizes (XS, 3XL, 4XL) per request within a few days.
                            </p>
                        </div>
                        <ReactWOW animation="fadeInRight" delay="0.3s">
                            <div>
                                <img src="/features/branded-apparel/deminimis-two.jpg " alt="rewards custom logo items" />
                            </div>
                        </ReactWOW>
                    </div>
                </section>
                <section className='feature-section steps' id="overview-cards"> 
                    <h1>How it Works</h1>
                    <div className='rewards_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="1" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div className='_option'>
                                <p className='step-header'>Setup Your Program</p>
                                <p>Choose which Rewards items works best for your company; customize how many points workers can earn to match your budget.</p>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className='rewards_how_it_works'>
                    <ReactWOW animation="fadeInLeft" delay="0.2s">
                        <div className='_option'>
                            <p className='step-header'>Let Your Company Culture Grow</p>
                            <p>Workers can earn points for completing those pesky onboarding tasks and day-to-day forms, sending kudos, etc.</p>
                        </div>
                    </ReactWOW>

                        <Badge className="badge" content="2" size="large"/>
                        <div className='empty'></div>
                    </div>

                    <div className='rewards_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="3" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.3s">
                            <div className='_option'>
                                <p className='step-header'>See the Results</p>
                                <p>Watch your company turnover reduce and satisfaction increase.</p>
                            </div>
                        </ReactWOW>

                    </div>

                </section>
            </main>
        </Layout>
    )
 }